import { ActionType, getType } from "typesafe-actions";
import * as casesActions from "./actions";
import { CasesActionTypes, CasesState, InterventionGroup, InterventionPlanStates } from "./types";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../../utils/LocalStorageUtils";
import _ from "lodash";
import { pdfTables } from "../types";

export type CasesAction = ActionType<typeof casesActions>;

const initialState: CasesState = {
  showWelcomePanel: !getBooleanFromLocalStorage(
    LocalStorageKeys.WelcomePanelHidden
  ),
  showGroupingStudentInfo: !getBooleanFromLocalStorage(
    LocalStorageKeys.GroupingStudentInfoHidden
  ),
  showThreeStepProgress: !getBooleanFromLocalStorage(
    LocalStorageKeys.ThreeStepProgressHidden
  ),
  showStartWorkingIntro: !getBooleanFromLocalStorage(
    LocalStorageKeys.StartWorkingIntro
  ),
  showNoGroupsIntro: !getBooleanFromLocalStorage(
    LocalStorageKeys.NoGroupsIntro
  ),
  showNoUnsavedGroupsIntro: !getBooleanFromLocalStorage(
    LocalStorageKeys.NoUnsavedGroupsIntro
  ),
  showNoAbcDataCollectionIntro: !getBooleanFromLocalStorage(
    LocalStorageKeys.NoAbcDataCollectionIntro
  ),
  showRecommendedGroups: !getBooleanFromLocalStorage(
    LocalStorageKeys.ShowRecommendedGroups
  ),
  listOrderSwitch: false,
  activeGroupsView: "grid",
  isEnterSpecificSkillActive: false,
  isCreateGroupManually: false,
  newGroupStudents: [],
  interventionGroups: [],
  archivedInterventionGroups: [],
  recommendedGroups: [],
  recommendedGroupsFromAPI: [],
  notFinishedGroups: [],
  staticData: {
    assessments: [],
    measurements: [],
  },
  modalsState: {
    groupingStudentsModal: false,
    groupDetailsModal: false,
    setGoalsModal: false,
    setGoalModal: false,
    setMissedGoal: false,
    setGoalChooseConcernModel: false,
    findInterventionModal: false,
    changePasswordModal: false,
    addInterventionModal: false,
    interventionLibraryModal: false,
    interventionCalledFromFBA: false,
    createNewInterventionModal: false,
    implementStepModal: false,
    editGroupModal: false,
    isSaveInState: false,
    interpretFbaModal: false,
    fbaModal: false,
    fbaModalTab: null,
    learnMoreModal: false,
    upsertAssessmentModal: false,
    interventionPlan: {
      selectedStudents: [],
      selectedStudentGoalMeasure: undefined,
      selectedStudentGoals: [],
      selectedStudentForWorkSpacePDF: [],
      selectedPDFForWorkSpacePDF: pdfTables,
      showOnlyActiveInterventions: true,
      showGoalLines: false,
      showStudentTrendsLines: false,
    },
    interventionGroupId: undefined,
    deleteInterventionLog: false,
    isRedirectToSetGoal: false,
  },
  isLoading: {
    getRecommendations: false,
    getNotFinishedGroups: false,
    getInterventionGroups: false,
    createInterventionGroup: false,
    createGasMeasurement: false,
    getAssessmentStaticData: false,
    getMeasurementStaticData: false,
    createStudentGoalForInterventionGroup: false,
    createStudentGoalForStudent: false,
    deleteStudentGoal: false,
    createIntervention: false,
    getInterventions: false,
    addInterventionsToGroup: false,
    addInterventionSchoolResources: false,
    updateInterventionGroup: false,
    deleteLoggedProgress: false,
    patchInterventionGroup: false,
    deleteInterventionGroup: false,
    finishInterventionGroup: false,
    unsaveInterventionGroup: false,
    createFba: false,
    deleteFba: false,
    updateFba: false,
    createFbaABC: false,
    getFbas: false,
    createFbaObservation: false,
    completeFbaObservation: false,
    discontinueIntGroupInt: false,
    deleteInterventionLog: false,
    loaderIntGroupBehavior: false,
    loaderIntGroupBehaviorArchived: false,
    loaderIntGroupReading: false,
    loaderIntGroupReadingArchived: false,
    loaderIntGroupStudentDetail: false,
    loaderIntGroupStudentDetailArchived: false,
  },
  errors: {},
  interventions: [],
  fbas: [],
  lessonNotes: [],
  attendances: [],
  groupCardFocusTab: "matrix",
  tabName: "groups/reading",
  isStudentDetail: false,
  isGroupDetail: false,
  shareDataWith: false,
  notShareDataWith: false,
  displayGroupName: 'active',
  //bothStudentGroupUpdated: undefined,
  loggedProgressData: undefined,
  isClickedFromAlert: false,
  openRecommendationGroupFromData: false
};

const reducer = (
  state: CasesState = initialState,
  action: CasesAction
): CasesState => {
  switch (action.type) {
    case CasesActionTypes.ADD_STUDENTS_TO_NEW_GROUP:
      return {
        ...state,
        newGroupStudents: [
          ...state.newGroupStudents,
          ...action.payload.students,
        ],
      };
    case CasesActionTypes.REMOVE_STUDENT_FROM_NEW_GROUP:
      return {
        ...state,
        newGroupStudents: state.newGroupStudents.filter(
          (s) => s.id !== action.payload.studentId
        ),
      };
    case CasesActionTypes.REMOVE_ALL_STUDENT_FROM_NEW_GROUP:
      return {
        ...state,
        newGroupStudents: [],
      };

    case CasesActionTypes.CHANGE_SELECTED_INTERVENTION_GROUP:
      return {
        ...state,
        selectedInterventionGroup: action.payload.interventionGroupInfo,
      };

    case CasesActionTypes.CHANGE_SELECTED_STUDENT_GOAL:
      return {
        ...state,
        selectedStudentGoal: action.payload.studentGoal,
      };

    case CasesActionTypes.OPEN_GROUPING_STUDENTS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          groupingStudentsModal: true,
        },
      };
    case CasesActionTypes.HIDE_GROUPING_STUDENTS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          groupingStudentsModal: false,
        },
      };

    case CasesActionTypes.OPEN_LEARN_MORE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          learnMoreModal: true,
        },
      };
    case CasesActionTypes.HIDE_LEARN_MORE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          learnMoreModal: false,
        },
      };

    case CasesActionTypes.HIDE_WELCOME_PANEL: {
      localStorage.setItem(
        LocalStorageKeys.WelcomePanelHidden,
        JSON.stringify(true)
      );
      return {
        ...state,
        showWelcomePanel: false,
      };
    }

    case CasesActionTypes.HIDE_GROUPING_STUDENT_INFO_PANEL: {
      localStorage.setItem(
        LocalStorageKeys.GroupingStudentInfoHidden,
        JSON.stringify(true)
      );
      return {
        ...state,
        showGroupingStudentInfo: false,
      };
    }

    case CasesActionTypes.HIDE_THREE_STEP_PROGRESS: {
      localStorage.setItem(
        LocalStorageKeys.ThreeStepProgressHidden,
        JSON.stringify(true)
      );
      return {
        ...state,
        showThreeStepProgress: false,
      };
    }

    case CasesActionTypes.SET_SHOW_START_WORKING_INTRO: {
      localStorage.setItem(
        LocalStorageKeys.StartWorkingIntro,
        JSON.stringify(!action.payload)
      );
      return {
        ...state,
        showStartWorkingIntro: action.payload,
      };
    }

    case CasesActionTypes.SET_SHOW_NO_GROUPS_INTRO: {
      localStorage.setItem(
        LocalStorageKeys.NoGroupsIntro,
        JSON.stringify(!action.payload)
      );
      return {
        ...state,
        showNoGroupsIntro: action.payload,
      };
    }

    case CasesActionTypes.SET_SHOW_NO_UNSAVED_GROUPS_INTRO: {
      localStorage.setItem(
        LocalStorageKeys.NoUnsavedGroupsIntro,
        JSON.stringify(!action.payload)
      );
      return {
        ...state,
        showNoUnsavedGroupsIntro: action.payload,
      };
    }

    case CasesActionTypes.SET_SHOW_NO_ABC_DATA_COLLECTION_INTRO: {
      localStorage.setItem(
        LocalStorageKeys.NoAbcDataCollectionIntro,
        JSON.stringify(!action.payload)
      );
      return {
        ...state,
        showNoAbcDataCollectionIntro: action.payload,
      };
    }

    case CasesActionTypes.CREATE_GROUP_MANUALLY:
      return {
        ...state,
        isCreateGroupManually: true,
      };
    case CasesActionTypes.CANCEL_CREATE_GROUP_MANUALLY:
      return {
        ...state,
        isCreateGroupManually: false,
        newGroupStudents: [],
      };

    case CasesActionTypes.OPEN_GROUP_DETAILS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          groupDetailsModal: true,
          groupingStudentsModal: false,
          interventionLibraryModal: false,
        },
      };
    case CasesActionTypes.HIDE_GROUP_DETAILS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          groupDetailsModal: false,
          // groupingStudentsModal: true //todo maybe remove
        },
      };

    case CasesActionTypes.CHANGE_SET_GOALS_MODAL:
      return {
        ...state,
        selectedInterventionGroup: state.modalsState.interventionPlan.show
          ? state.selectedInterventionGroup
          : action.payload.interventionGroup,
        selectedStudentGoal: action.payload.studentGoal,
        modalsState: {
          ...state.modalsState,
          setGoalsModal: action.payload.show,
        },
      };

    case CasesActionTypes.OPEN_SET_GOAL_CHOOSE_CONCERN_MODAL:
      return {
        ...state,
        selectedStudentGoal: action.payload.studentGoal,
        selectedInterventionGroup: action.payload.interventionGroup,
        modalsState: {
          ...state.modalsState,
          setGoalModal: true,
          studentGoalOptions: action.payload.studentGoalOptions,
          showAnalyzeFbaResultsModal: false,
          setMissedGoal: action.payload.setMissedGoal
        },
      };
    case CasesActionTypes.HIDE_SET_GOAL_CHOOSE_CONCERN_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          setGoalModal: false,
          studentGoalOptions: undefined,
          setMissedGoal: false,
        },
      };

    case getType(casesActions.backToFba):
      return {
        ...state,
        selectedFba: state.fbas.find(
          (fba) => fba.id === state.modalsState.studentGoalOptions?.fbaId
        ),
        modalsState: {
          ...state.modalsState,
          setGoalModal: false,
          showAnalyzeFbaResultsModal: true,
        },
      };
    case CasesActionTypes.ON_ENTER_SPECIFIC_SKILL:
      return {
        ...state,
        isEnterSpecificSkillActive: true,
      };
    case CasesActionTypes.CLICK_GOT_IT:
      return {
        ...state,
        isEnterSpecificSkillActive: false,
      };

    case CasesActionTypes.OPEN_FIND_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          findInterventionModal: true,
          // groupDetailsModal: false
        },
      };
    case CasesActionTypes.HIDE_FIND_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          findInterventionModal: false,
          // groupDetailsModal: true // todo mb remove
        },
      };

    case CasesActionTypes.OPEN_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          changePasswordModal: true,
        },
      };
    case CasesActionTypes.HIDE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          changePasswordModal: false,
        },
      };

    case CasesActionTypes.OPEN_ADD_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          addInterventionModal: true,
          isSaveInState: action.payload.isSaveInState
            ? action.payload.isSaveInState
            : false,
        },
      };
    case CasesActionTypes.HIDE_ADD_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          addInterventionModal: false,
          isSaveInState: false,
        },
      };

    case CasesActionTypes.OPEN_INTERVENTION_LIBRARY_MODAL: {
      let selectedIntervention = state.interventions.filter((intervention) =>
        intervention.id === action.payload.selectedIntervention?.id
      )
      return {
        ...state,
        selectedIntervention: selectedIntervention.length ? selectedIntervention[0] : action.payload.selectedIntervention,
        modalsState: {
          ...state.modalsState,
          interventionLibraryModal: true,
          findInterventionModal: false,
          isSaveInState: action.payload.isSaveInState
            ? action.payload.isSaveInState
            : false,
          interventionLibraryModalCallbacks: action.payload.callbacks,
        },
      };

    }
    case CasesActionTypes.HIDE_INTERVENTION_LIBRARY_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionLibraryModal: false,
          findInterventionModal: false,
          isSaveInState: false,
        },
      };

    case CasesActionTypes.OPEN_EDIT_GROUP_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, editGroupModal: true },
      };
    case CasesActionTypes.HIDE_EDIT_GROUP_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, editGroupModal: false },
      };

    case CasesActionTypes.OPEN_IMPLEMENT_STEP_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          implementStepModal: true,
        },
      };
    case CasesActionTypes.HIDE_IMPLEMENT_STEP_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          implementStepModal: false,
        },
      };

    case CasesActionTypes.HIDE_ALL_MODAL:
      return {
        ...state,
        modalsState: initialState.modalsState,
      };

    case CasesActionTypes.GET_RECOMMENDATIONS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getRecommendations: true },
      };
    case CasesActionTypes.GET_RECOMMENDATIONS_SUCCESS: {
      if(action.payload.isTabMatched) {
        return {
          ...state,
          isLoading: { ...state.isLoading, getRecommendations: false },
          errors: { ...state.errors, getRecommendations: undefined },
          recommendedGroups: action.payload.recommendedGroups,
          recommendedGroupsFromAPI: [...action.payload.recommendedGroups],
        };
      } else {
        return {
          ...state,
          isLoading: { ...state.isLoading, getRecommendations: false },
          errors: { ...state.errors, getRecommendations: undefined }
        };
      }
    }
    case CasesActionTypes.GET_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getRecommendations: false },
        errors: { ...state.errors, getRecommendations: action.payload.error },
      };

    case CasesActionTypes.CREATE_INTERVENTION_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, createIntervention: true },
      };
    case CasesActionTypes.CREATE_INTERVENTION_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, createIntervention: false },
        errors: { ...state.errors, createIntervention: undefined },
        interventions: [...state.interventions, action.payload.intervention],
        interventionGroups: state.interventionGroups.map(
          (interventionGroup) => {
            if (
              interventionGroup.id ===
              action.payload.intervention.specific_group
            ) {
              interventionGroup = {
                ...interventionGroup,
                interventions: [...interventionGroup.interventions, action.payload.intervention],
                intervention_plan: action.payload.intervention.intervention_plan || null
              }
            }
            return interventionGroup;
          }
        ),
        notFinishedGroups: state.notFinishedGroups.map((interventionGroup) => {
          if (
            interventionGroup.id === action.payload.intervention.specific_group
          ) {
            interventionGroup = {
              ...interventionGroup,
              interventions: [...interventionGroup.interventions, action.payload.intervention],
              intervention_plan: action.payload.intervention.intervention_plan || null
            }
          }
          return interventionGroup;
        }),
        selectedInterventionGroup: state.selectedInterventionGroup
        ? {
            ...state.selectedInterventionGroup,
            interventions: [...state.selectedInterventionGroup.interventions, action.payload.intervention],
            intervention_plan: action.payload.intervention.intervention_plan || null
          }
        : undefined
      };
    case CasesActionTypes.CREATE_INTERVENTION_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, createIntervention: false },
        errors: { ...state.errors, createIntervention: action.payload.error },
      };

    case getType(casesActions.updateInterventionAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateIntervention: true },
        errors: { ...state.errors, updateIntervention: undefined },
      };
    case getType(casesActions.updateInterventionAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateIntervention: false },
        interventions: state.interventions.map((intervention) =>
          intervention.id === action.payload.id ? action.payload : intervention
        ),
      };
    case getType(casesActions.updateInterventionAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateIntervention: false },
        errors: { ...state.errors, updateIntervention: action.payload },
      };

    case CasesActionTypes.GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_START: {
      let activeTab = action.payload.activeTab;
      let archived = action.payload.archived;
      let studentId = action.payload.studentId;
      if(activeTab && (activeTab == 'groups/behavior') && !archived){
        state.isLoading = {
          ...state.isLoading,
          loaderIntGroupBehavior: true
        }
      } else if(activeTab && (activeTab == 'groups/reading') && !archived){
        state.isLoading = {
          ...state.isLoading,
          loaderIntGroupReading: true
        }
      } else if(activeTab && (activeTab == 'groups/behavior') && archived){
        state.isLoading = {
          ...state.isLoading,
          loaderIntGroupBehaviorArchived: true
        }
      } else if(activeTab && (activeTab == 'groups/reading') && archived){
        state.isLoading = {
          ...state.isLoading,
          loaderIntGroupReadingArchived: true
        }
      } else if(studentId && !archived){
        state.isLoading = {
          ...state.isLoading,
          loaderIntGroupStudentDetail: true
        }
      } else if(studentId && archived){
        state.isLoading = {
          ...state.isLoading,
          loaderIntGroupStudentDetailArchived: true
        }
      }
      
      return {
        ...state,
        isLoading: { ...state.isLoading},
      };
    }

    case CasesActionTypes.GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_SUCCESS: {
      let activeTab = action.payload.activeTab;
      let archived = action.payload.archived;
      let studentId = action.payload.studentId;
      if(activeTab && (activeTab == 'groups/behavior') && !archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupBehavior: false
        }
        state.errors = {
          ...state.errors,
        loaderIntGroupBehavior: undefined
        }
      } else if(activeTab && (activeTab == 'groups/reading') && !archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupReading: false
        }
        state.errors = {
          ...state.errors,
        loaderIntGroupReading:  undefined
        }
      } else if(activeTab && (activeTab == 'groups/behavior') && archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupBehaviorArchived: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupBehaviorArchived: undefined
        }
      } else if(activeTab && (activeTab == 'groups/reading') && archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupReadingArchived: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupReadingArchived: undefined
        }
      } else if(studentId && !archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupStudentDetail: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupStudentDetail: undefined
        }
      } else if(studentId && archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupStudentDetailArchived: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupStudentDetailArchived: undefined
        }
      }
      return {
        ...state,
        isLoading: { ...state.isLoading },
        errors: { ...state.errors},
        interventionGroups: !archived
          ? [...action.payload.interventionGroups]
          : state.interventionGroups,
        archivedInterventionGroups: archived
          ? [...action.payload.interventionGroups]
          : state.archivedInterventionGroups,
      };
    }
    case CasesActionTypes.GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_FAILURE: {
      let activeTab = action.payload.activeTab;
      let archived = action.payload.archived;
      let studentId = action.payload.studentId;
      if(activeTab && (activeTab == 'groups/behavior') && !archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupBehavior: false
        }
        state.errors = {
          ...state.errors,
        loaderIntGroupBehavior: action.payload.error
        }
      } else if(activeTab && (activeTab == 'groups/reading') && !archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupReading: false
        }
        state.errors = {
          ...state.errors,
        loaderIntGroupReading:  action.payload.error
        }
      } else if(activeTab && (activeTab == 'groups/behavior') && archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupBehaviorArchived: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupBehaviorArchived: action.payload.error
        }
      } else if(activeTab && (activeTab == 'groups/reading') && archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupReadingArchived: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupReadingArchived: action.payload.error
        }
      } else if(studentId && !archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupStudentDetail: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupStudentDetail: action.payload.error
        }
      } else if(studentId && archived){
        state.isLoading = {
          ...state.isLoading,
        loaderIntGroupStudentDetailArchived: false
        }
        state.errors = {
          ...state.errors,
          loaderIntGroupStudentDetailArchived: action.payload.error
        }
      }

      return {
        ...state,
        isLoading: { ...state.isLoading},
        errors: { ...state.errors},
      }
    }

    case CasesActionTypes.GET_INTERVENTION_GROUPS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionGroups: true },
      };
    case CasesActionTypes.GET_INTERVENTION_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionGroups: false },
        errors: { ...state.errors, getInterventionGroups: undefined },
        interventionGroups: !action.payload.archived
          ? [...action.payload.interventionGroups]
          : state.interventionGroups,
        archivedInterventionGroups: action.payload.archived
          ? [...action.payload.interventionGroups]
          : state.archivedInterventionGroups,
      };
    case CasesActionTypes.GET_INTERVENTION_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInterventionGroups: false },
        errors: {
          ...state.errors,
          getInterventionGroups: action.payload.error,
        },
      };

    case CasesActionTypes.CHANGE_INTERVENTION_GROUP_LATEST_MEETING:
      return {
        ...state,
        interventionGroups: state.interventionGroups.map((ig) =>
          ig.id === action.payload.meeting.intervention_group
            ? { ...ig, latest_meeting: action.payload.meeting }
            : ig
        ),
      };

    case CasesActionTypes.GET_NOT_FINISHED_GROUPS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotFinishedGroups: true },
      };
    case CasesActionTypes.GET_NOT_FINISHED_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotFinishedGroups: false },
        errors: { ...state.errors, getNotFinishedGroups: undefined },
        notFinishedGroups: [...action.payload.interventionGroups],
      };
    case CasesActionTypes.GET_NOT_FINISHED_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotFinishedGroups: false },
        errors: { ...state.errors, getNotFinishedGroups: action.payload.error },
      };

    case CasesActionTypes.CREATE_INTERVENTION_GROUPS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createInterventionGroup: true,
        },
        silentGroupCreation: action.payload,
      };
    case CasesActionTypes.CREATE_INTERVENTION_GROUPS_SUCCESS:
      // console.log(action.payload.interventionGroup)
      return {
        ...state,
        isLoading: { ...state.isLoading, createInterventionGroup: false },
        errors: { ...state.errors, createInterventionGroup: undefined },
        selectedInterventionGroup: action.payload.interventionGroup,
        interventionGroups: action.payload.fromRecommendation
          ? [...state.interventionGroups]
          : [action.payload.interventionGroup, ...state.interventionGroups],
        notFinishedGroups: action.payload.interventionGroup.finished
          ? state.notFinishedGroups
          : [action.payload.interventionGroup, ...state.notFinishedGroups],
        recommendedGroups: [
          ...state.recommendedGroups.filter(
            (gr) => (gr.id !== action.payload.interventionGroup.oldId)
          ),
        ],
      };
    case CasesActionTypes.CREATE_INTERVENTION_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, createInterventionGroup: false },
        errors: {
          ...state.errors,
          createInterventionGroup: action.payload.error,
        },
      };

    case CasesActionTypes.GET_ASSESSMENT_STATIC_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAssessmentStaticData: true },
      };
    case CasesActionTypes.GET_ASSESSMENT_STATIC_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAssessmentStaticData: false },
        errors: { ...state.errors, getAssessmentStaticData: undefined },
        staticData: {
          ...state.staticData,
          assessments: action.payload.assessments,
        },
      };
    case CasesActionTypes.GET_ASSESSMENT_STATIC_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getAssessmentStaticData: false },
        errors: {
          ...state.errors,
          getAssessmentStaticData: action.payload.error,
        },
      };

    case CasesActionTypes.GET_MEASUREMENT_STATIC_DATA_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getMeasurementStaticData: true },
      };
    case CasesActionTypes.GET_MEASUREMENT_STATIC_DATA_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getMeasurementStaticData: false },
        errors: { ...state.errors, getMeasurementStaticData: undefined },
        staticData: {
          ...state.staticData,
          measurements: action.payload.measurements,
        },
      };
    case CasesActionTypes.GET_MEASUREMENT_STATIC_DATA_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getMeasurementStaticData: false },
        errors: {
          ...state.errors,
          getMeasurementStaticData: action.payload.error,
        },
      };

    case CasesActionTypes.CREATE_GAS_MEASUREMENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, createGasMeasurement: true },
      };
    case CasesActionTypes.CREATE_GAS_MEASUREMENT_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, createGasMeasurement: false },
        gasMeasurement: action.payload.gasMeasurement,
        errors: { ...state.errors, createGasMeasurement: undefined },
      };
    case CasesActionTypes.CREATE_GAS_MEASUREMENT_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, createGasMeasurement: false },
        errors: {
          ...state.errors,
          createGasMeasurement: action.payload.error,
        },
      };

    case CasesActionTypes.CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createStudentGoalForInterventionGroup: true,
        },
      };
    case CasesActionTypes.CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createStudentGoalForInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          createStudentGoalForInterventionGroup: undefined,
        },
        interventionGroups: state.interventionGroups.map((x) => {
          if (action.payload.interventionGroup.id === x.id) {
            return action.payload.interventionGroup;
          }
          return x;
        }),
        notFinishedGroups: action.payload.interventionGroup.finished
          ? state.notFinishedGroups
          : state.notFinishedGroups.map((g) =>
              g.id === action.payload.interventionGroup.id
                ? action.payload.interventionGroup
                : g
            ),
        selectedInterventionGroup: action.payload.interventionGroup,
        fbas: state.modalsState.studentGoalOptions?.fbaId
          ? state.fbas.map((fba) =>
              fba.id === state.modalsState.studentGoalOptions!.fbaId
                ? {
                    ...fba,
                    intervention_group:
                      action.payload.interventionGroup.id ===
                      fba.intervention_group?.id
                        ? action.payload.interventionGroup
                        : fba.intervention_group,
                  }
                : fba
            )
          : state.fbas,
        selectedFba:
          state.selectedFba &&
          state.selectedFba.id === state.modalsState.studentGoalOptions?.fbaId
            ? {
                ...state.selectedFba,
                intervention_group: action.payload.interventionGroup,
              }
            : state.selectedFba,
      };
    }
    case CasesActionTypes.CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createStudentGoalForInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          createStudentGoalForInterventionGroup: action.payload.error,
        },
      };

    case CasesActionTypes.CREATE_STUDENT_GOAL_FOR_STUDENT_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createStudentGoalForStudent: true,
        },
      };
    case CasesActionTypes.CREATE_STUDENT_GOAL_FOR_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createStudentGoalForStudent: false,
        },
        errors: {
          ...state.errors,
          createStudentGoalForStudent: undefined,
        },
        interventionGroups: [...state.interventionGroups.map((x) => {
          if (action.payload.interventionGroup.id === x.id) {
            if(action.payload.isMultiAPICallsForSetGoal) {
              //RC-922 this code not use as of now
              const st_goals = x.student_goals;
              const filteredPayloadStGoals = action.payload.interventionGroup.student_goals
                                              .filter((sg) => !st_goals.some((stg) => stg.id == sg.id));
              if(filteredPayloadStGoals.length) {
                st_goals.push(filteredPayloadStGoals[0])
              }
              let interventionGroupPayload = {
                ...action.payload.interventionGroup,
                student_goals:[...st_goals]
              }
              return interventionGroupPayload;
            } else {
              //RC-1558 old activity merged
              let payload: InterventionGroup = action.payload.interventionGroup;
              payload = {
                ...payload,
                latest_activity: x.latest_activity
              }
              return payload;
            }
          }
          return x;
        })],
        notFinishedGroups: action.payload.interventionGroup.finished
          ? state.notFinishedGroups
          : state.notFinishedGroups.map((g) => {
              if(g.id === action.payload.interventionGroup.id) {
                  if(action.payload.isMultiAPICallsForSetGoal) {
                    const st_goals = g.student_goals;
                    const filteredPayloadStGoals = action.payload.interventionGroup.student_goals
                                                    .filter((sg) => !st_goals.some((stg) => stg.id == sg.id));
                    if(filteredPayloadStGoals.length) {
                      st_goals.push(filteredPayloadStGoals[0])
                    }
                    let interventionGroupPayload = {
                      ...action.payload.interventionGroup,
                      student_goals:[...st_goals]
                    }
                    return interventionGroupPayload;
                  } else {
                    return action.payload.interventionGroup;
                  }
              }
              return g;
            }),
        selectedInterventionGroup: action.payload.interventionGroup,
      };
    case CasesActionTypes.CREATE_STUDENT_GOAL_FOR_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createStudentGoalForStudent: false,
        },
        errors: {
          ...state.errors,
          createStudentGoalForStudent: action.payload.error,
        },
      };

    // todo remove DELETE_STUDENT_GOAL if api has changed and it's not needed
    case CasesActionTypes.DELETE_STUDENT_GOAL_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteStudentGoal: true,
        },
      };
    case CasesActionTypes.DELETE_STUDENT_GOAL_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteStudentGoal: false,
        },
        errors: {
          ...state.errors,
          deleteStudentGoal: undefined,
        },
        /*interventionGroups: state.interventionGroups.map(x => {
          if (action.payload.interventionGroup.id === x.id) {
            return action.payload.interventionGroup;
          }
          return x;
        }),
        notFinishedGroups: action.payload.interventionGroup.finished
          ? state.notFinishedGroups
          : state.notFinishedGroups.map(g =>
              g.id === action.payload.interventionGroup.id
                ? action.payload.interventionGroup
                : g,
            ),
        selectedInterventionGroup: action.payload.interventionGroup,*/
      };
    case CasesActionTypes.DELETE_STUDENT_GOAL_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteStudentGoal: false,
        },
        errors: {
          ...state.errors,
          deleteStudentGoal: action.payload.error,
        },
      };

    case CasesActionTypes.GET_INTERVENTIONS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInterventions: true,
        },
      };
    case CasesActionTypes.GET_INTERVENTIONS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInterventions: false,
        },
        errors: {
          ...state.errors,
          getInterventions: undefined,
        },
        interventions: action.payload.interventions,
      };
    case CasesActionTypes.GET_INTERVENTIONS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInterventions: false,
        },
        errors: {
          ...state.errors,
          getInterventions: action.payload.error,
        },
      };

    case CasesActionTypes.ADD_INTERVENTIONS_TO_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addInterventionsToGroup: true,
        },
      };
    case CasesActionTypes.ADD_INTERVENTIONS_TO_GROUP_SUCCESS: {
      const updatedGroup = state.selectedInterventionGroup
        ? {
            ...state.selectedInterventionGroup,
            ...action.payload.interventionGroup,
          }
        : undefined;

      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addInterventionsToGroup: false,
        },
        errors: {
          ...state.errors,
          addInterventionsToGroup: undefined,
        },
        selectedInterventionGroup: updatedGroup,
        interventionGroups: state.interventionGroups.map(
          (interventionGroup) => {
            if (interventionGroup.id === action.payload.interventionGroupId) {
              return {
                ...interventionGroup,
                ...action.payload.interventionGroup,
              };
            }
            return interventionGroup;
          }
        ),
        notFinishedGroups: state.notFinishedGroups.map((interventionGroup) => {
          if (interventionGroup.id === action.payload.interventionGroupId) {
            return {
              ...interventionGroup,
              ...action.payload.interventionGroup,
            };
          }
          return interventionGroup;
        }),
        fbas:
          updatedGroup &&
          state.fbas.some(
            (fba) =>
              fba.intervention_group &&
              fba.intervention_group.id === updatedGroup.id
          )
            ? state.fbas.map((fba) =>
                fba.intervention_group &&
                fba.intervention_group.id === updatedGroup.id
                  ? { ...fba, intervention_group: updatedGroup }
                  : fba
              )
            : state.fbas,
      };
    }
    case CasesActionTypes.ADD_INTERVENTIONS_TO_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addInterventionsToGroup: false,
        },
        errors: {
          ...state.errors,
          addInterventionsToGroup: action.payload.error,
        },
      };

    case CasesActionTypes.ADD_INTERVENTIONS_SCHOOL_RESOURCES_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addInterventionSchoolResources: true,
        },
      };
    case CasesActionTypes.ADD_INTERVENTIONS_SCHOOL_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addInterventionSchoolResources: false,
        },
        errors: {
          ...state.errors,
          addInterventionSchoolResources: undefined,
        },
        interventions: [...state.interventions, action.payload.intervention],
        createdIntervention: action.payload.intervention,
      };
    case CasesActionTypes.ADD_INTERVENTIONS_SCHOOL_RESOURCES_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          addInterventionSchoolResources: false,
        },
        errors: {
          ...state.errors,
          addInterventionSchoolResources: action.payload.error,
        },
      };

    case CasesActionTypes.UPDATE_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateInterventionGroup: true,
        },
      };
    case CasesActionTypes.UPDATE_INTERVENTION_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          updateInterventionGroup: undefined,
        },
        selectedInterventionGroup: {
          ...state.selectedInterventionGroup!,
          ...action.payload.interventionGroup,
        },
        interventionGroups: state.interventionGroups.map(
          (interventionGroup) => {
            if (interventionGroup.id === action.payload.interventionGroup.id) {
              return {
                ...state.selectedInterventionGroup!,
                ...action.payload.interventionGroup,
              };
            }
            return interventionGroup;
          }
        ),
      };
    case CasesActionTypes.UPDATE_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          updateInterventionGroup: action.payload.error,
        },
      };

    case CasesActionTypes.PROGRESS_TOWARDS_GOAL_DELETE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteLoggedProgress: true,
        },
      };
    case CasesActionTypes.PROGRESS_TOWARDS_GOAL_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteLoggedProgress: false,
        },
        errors: {
          ...state.errors,
          deleteLoggedProgress: undefined,
        },
        selectedInterventionGroup: {
          ...state.selectedInterventionGroup!,
          ...action.payload.interventionGroup,
        },
        interventionGroups: state.interventionGroups.map(
          (interventionGroup) => {
            if (interventionGroup.id === action.payload.interventionGroup.id) {
              return {
                ...state.selectedInterventionGroup!,
                ...action.payload.interventionGroup,
              };
            }
            return interventionGroup;
          }
        ),
      };
    case CasesActionTypes.PROGRESS_TOWARDS_GOAL_DELETE_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteLoggedProgress: false,
        },
        errors: {
          ...state.errors,
          deleteLoggedProgress: action.payload.error,
        },
      };

    case CasesActionTypes.PATCH_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          patchInterventionGroup: true,
        },
        //bothStudentGroupUpdated: undefined,
      };
    case CasesActionTypes.PATCH_INTERVENTION_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          patchInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          patchInterventionGroup: undefined,
        },
        selectedInterventionGroup:
          action.payload.interventionGroup.id ===
          state.selectedInterventionGroup?.id
            ? {
                ...state.selectedInterventionGroup,
                ...(action.payload.interventionGroup as InterventionGroup),
              }
            : state.selectedInterventionGroup,
        interventionGroups: state.interventionGroups.filter((group) => {
          if (group.id === action.payload.interventionGroup.id) {
            if (!action.payload.interventionGroup.archived) {
              return group;
            }
            return false
          }
          return group
        }).map(
          (interventionGroup) => {
            if (interventionGroup.id === action.payload.interventionGroup.id) {
              return {
                ...interventionGroup,
                ...action.payload.interventionGroup,
              };
            }
            return interventionGroup;
          }
        ),
        notFinishedGroups: state.notFinishedGroups.map((interventionGroup) => {
          if (interventionGroup.id === action.payload.interventionGroup.id) {
            return {
              ...interventionGroup,
              ...action.payload.interventionGroup,
            };
          }
          return interventionGroup;
        }),
        //bothStudentGroupUpdated: action.payload.bothStudentGroupUpdated
      };
    case CasesActionTypes.PATCH_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          patchInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          patchInterventionGroup: action.payload.error,
        },
        //bothStudentGroupUpdated: false
      };

    case CasesActionTypes.FINISH_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          finishInterventionGroup: true,
        },
      };
    case CasesActionTypes.FINISH_INTERVENTION_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          finishInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          finishInterventionGroup: undefined,
        },
        interventionGroups: state.interventionGroups.map((ig) => {
          if (ig.id === action.payload.interventionGroup.id) {
            return action.payload.interventionGroup;
          }
          return ig;
        }),
        notFinishedGroups: state.notFinishedGroups.filter(
          (interventionGroup) =>
            interventionGroup.id !== action.payload.interventionGroup.id
        ),
      };
    case CasesActionTypes.FINISH_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          finishInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          finishInterventionGroup: action.payload.error,
        },
      };

    case CasesActionTypes.CHANGE_RECOMMENDED_GROUP:
      return {
        ...state,
        recommendedGroups: [
          ...state.recommendedGroups.map((gr) =>
            gr.id === action.payload.oldGroup.id
              ? action.payload.changedGroup
              : gr
          ),
        ],
      };

    case CasesActionTypes.DISCARD_RECOMMENDED_GROUP:
      return {
        ...state,
        recommendedGroups: [
          ...state.recommendedGroups.filter(
            (gr) => gr !== action.payload.group
          ),
        ],
      };
    
    case CasesActionTypes.CALL_FROM_STUDENT_DETAIL:
      return {
        ...state,
        isStudentDetail: action.payload.isStudentDetail
      }
    
    case CasesActionTypes.CALL_FROM_GROUP_DETAIL:
      return {
        ...state,
        isGroupDetail: action.payload.isGroupDetail
      }

    case CasesActionTypes.SEND_SHARE_DATA_WITH:
      return {
        ...state,
        shareDataWith: action.payload.shareDataWith ?? false
      }

    case CasesActionTypes.SEND_NOT_SHARE_DATA_WITH:
      return {
        ...state,
        notShareDataWith: action.payload.notShareDataWith ?? false
      }  

    case CasesActionTypes.ADD_BLANK_RECOMMENDED_GROUP:
      return {
        ...state,
        recommendedGroups: [action.payload, ...state.recommendedGroups],
      };

    case CasesActionTypes.GET_STUDENT_FROM_EDUCATOR:
      return {
        ...state,
        selectedStudent: action.payload.student,
      };

    case CasesActionTypes.DELETE_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteInterventionGroup: true,
        },
      };
    case CasesActionTypes.DELETE_INTERVENTION_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          deleteInterventionGroup: undefined,
        },
        interventionGroups: state.interventionGroups.filter(
          (interventionGroup) =>
            interventionGroup.id !== action.payload.interventionGroupId
        ),
        notFinishedGroups: state.notFinishedGroups.filter(
          (interventionGroup) =>
            interventionGroup.id !== action.payload.interventionGroupId
        ),
      };
    case CasesActionTypes.DELETE_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          deleteInterventionGroup: action.payload.error,
        },
      };

    case CasesActionTypes.UNSAVE_INTERVENTION_GROUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          unsaveInterventionGroup: true,
        },
      };
    case CasesActionTypes.UNSAVE_INTERVENTION_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          unsaveInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          unsaveInterventionGroup: undefined,
        },
        interventionGroups: state.interventionGroups.filter(
          (interventionGroup) =>
            interventionGroup.id !== action.payload.interventionGroup.id
        ),
        notFinishedGroups: state.notFinishedGroups.filter(
          (interventionGroup) =>
            interventionGroup.id !== action.payload.interventionGroup.id
        ),
        recommendedGroups: [
          {
            ...action.payload.groupRecommendation,
            id: state.recommendedGroups.length
              ? (_.minBy(state.recommendedGroups, (g) => g.id)?.id ?? -1) - 1
              : -1,
          },
          ...state.recommendedGroups,
        ],
      };
    case CasesActionTypes.UNSAVE_INTERVENTION_GROUP_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          unsaveInterventionGroup: false,
        },
        errors: {
          ...state.errors,
          unsaveInterventionGroup: action.payload.error,
        },
      };

    case CasesActionTypes.DISPLAY_GROUP_NAME: {
      return {
        ...state,
        displayGroupName: action.payload.displayGroupName
      }
  }
    case CasesActionTypes.SET_FBA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          fbaModal: action.payload.show,
          fbaModalTab: action.payload.tab,
        },
      };

    case CasesActionTypes.GET_FBAS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getFbas: true,
        },
        //todo add fba to list
      };
    case CasesActionTypes.GET_FBAS_SUCCESS:
      return {
        ...state,
        fbas: action.payload.fbas,
        isLoading: {
          ...state.isLoading,
          getFbas: false,
        },
        errors: {
          ...state.errors,
          getFbas: undefined,
        },
      };
    case CasesActionTypes.GET_FBAS_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getFbas: false,
        },
        errors: {
          ...state.errors,
          getFbas: action.payload.error,
        },
      };

    case CasesActionTypes.CREATE_FBA_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFba: true,
        },
      };
    case CasesActionTypes.CREATE_FBA_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFba: false,
        },
        errors: {
          ...state.errors,
          createFba: undefined,
        },
        selectedFba: action.payload.fba,
        fbas: [action.payload.fba, ...state.fbas],
      };
    case CasesActionTypes.CREATE_FBA_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFba: false,
        },
        errors: {
          ...state.errors,
          createFba: action.payload.error,
        },
      };

    case CasesActionTypes.DELETE_FBA_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteFba: true,
        },
      };
    case CasesActionTypes.DELETE_FBA_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteFba: false,
        },
        errors: {
          ...state.errors,
          deleteFba: undefined,
        },
        selectedFba:
          state.selectedFba?.id !== action.payload.fbaId
            ? state.selectedFba
            : undefined,
        fbas: state.fbas.filter((fba) => fba.id !== action.payload.fbaId),
      };
    case CasesActionTypes.DELETE_FBA_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteFba: false,
        },
        errors: {
          ...state.errors,
          deleteFba: action.payload.error,
        },
      };

    case CasesActionTypes.UPDATE_FBA_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateFba: true,
        },
      };
    case CasesActionTypes.UPDATE_FBA_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateFba: false,
        },
        errors: {
          ...state.errors,
          updateFba: undefined,
        },
        selectedFba: action.payload.fba,
        fbas: state.fbas.map((fba) => {
          if (fba.id === action.payload.fba.id) {
            return action.payload.fba;
          }
          return fba;
        }),
      };
    case CasesActionTypes.UPDATE_FBA_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateFba: false,
        },
        errors: {
          ...state.errors,
          updateFba: action.payload.error,
        },
      };

    case CasesActionTypes.CREATE_FBA_ABC_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFbaABC: true,
        },
        errors: {
          ...state.errors,
          createFbaABC: undefined,
        },
      };
    case CasesActionTypes.CREATE_FBA_ABC_SUCCESS: {
      const updatedFba = state.selectedFba
        ? {
            ...state.selectedFba,
            quick_log_observation: action.payload.isSingleAbc
              ? {
                  ...state.selectedFba.quick_log_observation,
                  observationabc: [
                    ...state.selectedFba.quick_log_observation.observationabc,
                    action.payload.fbaAbc,
                  ],
                }
              : state.selectedFba.quick_log_observation,
            observations: action.payload.isSingleAbc
              ? state.selectedFba.observations
              : state.selectedFba.observations.map((obs) =>
                  action.payload.observationId === obs.id
                    ? {
                        ...obs,
                        observationabc: [
                          ...obs.observationabc,
                          action.payload.fbaAbc,
                        ],
                      }
                    : obs
                ),
          }
        : undefined;

      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFbaABC: false,
        },
        errors: {
          ...state.errors,
          createFbaABC: undefined,
        },
        selectedFba: updatedFba,
        fbas: updatedFba
          ? state.fbas.map((fba) =>
              fba.id === updatedFba.id ? updatedFba : fba
            )
          : state.fbas,
        selectedFbaObservation: state?.selectedFbaObservation
          ? {
              ...state.selectedFbaObservation,
              observationabc: [
                ...state.selectedFbaObservation.observationabc,
                action.payload.fbaAbc,
              ],
            }
          : undefined,
      };
    }
    case CasesActionTypes.CREATE_FBA_ABC_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFbaABC: false,
        },
        errors: {
          ...state.errors,
          createFbaABC: action.payload.error,
        },
      };

    case CasesActionTypes.CREATE_FBA_OBSERVATION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFbaObservation: true,
        },
      };
    case CasesActionTypes.CREATE_FBA_OBSERVATION_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFbaObservation: false,
        },
        errors: {
          ...state.errors,
          createFbaObservation: undefined,
        },
        fbas: state.fbas.map((fba) =>
          action.payload.fba === fba.id
            ? { ...fba, observations: [...fba.observations, action.payload] }
            : fba
        ),
        selectedFba:
          action.payload.fba === state.selectedFba?.id
            ? {
                ...state.selectedFba,
                observations: [
                  ...state.selectedFba.observations,
                  action.payload,
                ],
              }
            : state.selectedFba,
        selectedFbaObservation: action.payload,
      };
    }
    case CasesActionTypes.CREATE_FBA_OBSERVATION_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createFbaObservation: false,
        },
        errors: {
          ...state.errors,
          createFbaObservation: action.payload.error,
        },
      };

    case getType(casesActions.getFbaObservationAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, getFbaObservation: true },
        errors: { ...state.errors, getFbaObservation: undefined },
      };
    case getType(casesActions.getFbaObservationAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, getFbaObservation: false },
        fbas: state.fbas.map((fba) =>
          fba.id === action.payload.fba
            ? {
                ...fba,
                observations: fba.observations.map((obs) =>
                  obs.id === action.payload.id ? action.payload : obs
                ),
              }
            : fba
        ),
      };
    case getType(casesActions.getFbaObservationAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, getFbaObservation: false },
        errors: { ...state.errors, getFbaObservation: action.payload },
      };

    case CasesActionTypes.CHANGE_SELECTED_OBSERVATION: {
      return {
        ...state,
        selectedFbaObservation: action.payload.fbaObs,
        selectedFba: action.payload.fbaObs ? state.selectedFba : undefined,
      };
    }

    case CasesActionTypes.CHANGE_SELECTED_FBA: {
      return {
        ...state,
        selectedFba: action.payload.fba,
      };
    }

    case CasesActionTypes.COMPLETE_FBA_OBSERVATION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          completeFbaObservation: true,
        },
      };
    case CasesActionTypes.COMPLETE_FBA_OBSERVATION_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          completeFbaObservation: false,
        },
        errors: {
          ...state.errors,
          completeFbaObservation: undefined,
        },
        fbas: state.fbas.map((fba) =>
          fba.id === action.payload.fba
            ? {
                ...fba,
                observations: fba.observations.map((obs) =>
                  obs.id === action.payload.id ? action.payload : obs
                ),
              }
            : fba
        ),
        selectedFbaObservation: undefined,
      };
    case CasesActionTypes.COMPLETE_FBA_OBSERVATION_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          completeFbaObservation: false,
        },
        errors: {
          ...state.errors,
          completeFbaObservation: action.payload.error,
        },
      };

    case CasesActionTypes.OPEN_INTERPRET_FBA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interpretFbaModal: true,
        },
      };
    case CasesActionTypes.HIDE_INTERPRET_FBA_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interpretFbaModal: false,
        },
      };

    case CasesActionTypes.OPEN_CREATE_NEW_INTERVENTION_MODAL: {
      let selectedIntervention = state.interventions.filter((intervention) =>
        intervention.id === action.payload.selectedIntervention?.id
      )

      return {
        ...state,
        selectedIntervention: selectedIntervention.length ? selectedIntervention[0] : action.payload.selectedIntervention,
        modalsState: {
          ...state.modalsState,
          createNewInterventionModal: true,
          interventionGroupId: action.payload.interventionGroupId,
          createNewInterventionModalConfig: action.payload.options,
        },
      };
    }
    case CasesActionTypes.HIDE_CREATE_NEW_INTERVENTION_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          createNewInterventionModal: false,
          createNewInterventionModalConfig: undefined,
        },
      };

    case CasesActionTypes.CHANGE_SELECTED_INTERVENTION:{
      let selectedIntervention = state.interventions.filter((intervention) =>
      intervention.id === action.payload.selectedIntervention?.id
      )

      return {
        ...state,
        selectedIntervention: selectedIntervention.length ? selectedIntervention[0] : action.payload.selectedIntervention,
      };

    }

    case CasesActionTypes.OPEN_UPSERT_ASSESSMENT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          upsertAssessmentModal: true,
        },
        selectedAssessment: action.payload.assessment,
      };
    case CasesActionTypes.HIDE_UPSERT_ASSESSMENT_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          upsertAssessmentModal: false,
        },
        selectedAssessment: undefined,
      };

    case CasesActionTypes.OPEN_GROUP_INTERVENTIONS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          groupInterventionsModal: true,
        },
      };
    case CasesActionTypes.HIDE_GROUP_INTERVENTIONS_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          groupInterventionsModal: false,
        },
      };

    case CasesActionTypes.CHANGE_ASSESSMENT_FILTER:
      return {
        ...state,
        assessmentFilter: action.payload.assessmentFilter,
      };

    case CasesActionTypes.UPDATE_PROGRESS_OVERVIEW: {
      const interventionGroups = state.interventionGroups.map((ig) => {
        if (ig.id === action.payload.interventionGroup.id) {
          return action.payload.interventionGroup;
        } else {
          return ig;
        }
      });

      return {
        ...state,
        selectedInterventionGroup: state.selectedInterventionGroup
          ? action.payload.interventionGroup
          : undefined,
        interventionGroups,
      };
    }

    case CasesActionTypes.DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, discontinueIntGroupInt: true },
      };
    case CasesActionTypes.DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_SUCCESS: {
      const updated: InterventionGroup | undefined =
        action.payload.interventionGroup &&
        state.selectedInterventionGroup &&
        state.selectedInterventionGroup.id ===
          action.payload.interventionGroupId
          ? {
              ...state.selectedInterventionGroup,
              archived: action.payload.interventionGroup.archived,
              students: action.payload.interventionGroup.students,
              student_goals: action.payload.interventionGroup.student_goals,
              alert_reason: action.payload.interventionGroup.alert_reason,
              alert_reason_text: action.payload.interventionGroup.alert_reason_text,
            }
          : state.selectedInterventionGroup;

      return {
        ...state,
        isLoading: { ...state.isLoading, discontinueIntGroupInt: false },
        interventionGroups: action.payload.interventionGroup
          ? action.payload.interventionGroup.archived
            ? state.interventionGroups.filter(
                (ig) => ig.id !== action.payload.interventionGroupId
              )
            : state.interventionGroups.map((ig) =>
                ig.id === action.payload.interventionGroupId ? updated! : ig
              )
          : state.interventionGroups,
        archivedInterventionGroups: action.payload.interventionGroup?.archived
          ? [
              ...state.archivedInterventionGroups,
              action.payload.interventionGroup,
            ]
          : state.archivedInterventionGroups,
        selectedInterventionGroup:
          state.selectedInterventionGroup &&
          state.selectedInterventionGroup.id ===
            action.payload.interventionGroupId
            ? updated
            : state.selectedInterventionGroup,
      };
    }
    case CasesActionTypes.DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, discontinueIntGroupInt: false },
      };

    case CasesActionTypes.OPEN_DELETE_INTERVENTION_LOG_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, deleteInterventionLog: true },
      };
    case CasesActionTypes.CLOSE_DELETE_INTERVENTION_LOG_MODAL:
      return {
        ...state,
        modalsState: { ...state.modalsState, deleteInterventionLog: false },
      };
    
    case CasesActionTypes.ACTIVE_TAB_URL: 
      return {
        ...state,
        tabName: action.payload,
      };

    case getType(casesActions.getLessonNotesAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, getLessonNotes: true },
        errors: { ...state.errors, getLessonNotes: undefined },
      };
    case getType(casesActions.getLessonNotesAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, getLessonNotes: false },
        lessonNotes: action.payload,
      };
    case getType(casesActions.getLessonNotesAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, getLessonNotes: false },
        errors: { ...state.errors, getLessonNotes: action.payload },
      };

    case getType(casesActions.createLessonNotesAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, createLessonNotes: true },
        errors: { ...state.errors, createLessonNotes: undefined },
      };
      case getType(casesActions.createLessonNotesAction.success): 
      {
        state.interventionGroups = [...state.interventionGroups.map((interventionGroup: InterventionGroup) => {
            return interventionGroup && interventionGroup.id === action.payload.group?.id
                    ? {
                        ...interventionGroup,
                        alert_reason: action.payload.group.alert_reason,
                        alert_reason_text: action.payload.group.alert_reason_text,
                      }
                    : interventionGroup
        })]

        state.selectedInterventionGroup =
          state.selectedInterventionGroup &&
          state.selectedInterventionGroup.id ===
          action.payload.group?.id
            ? {
                ...state.selectedInterventionGroup,
                alert_reason: action.payload.group.alert_reason,
                alert_reason_text: action.payload.group.alert_reason_text,
              }
            : state.selectedInterventionGroup;
  
          return {
            ...state,
            isLoading: { ...state.isLoading, createLessonNotes: false },
            lessonNotes: [action.payload, ...state.lessonNotes],
          };
      }
    case getType(casesActions.createLessonNotesAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, createLessonNotes: false },
        errors: { ...state.errors, createLessonNotes: action.payload },
      };

    case getType(casesActions.updateLessonNotesAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateLessonNotes: true },
        errors: { ...state.errors, updateLessonNotes: undefined },
      };
    case getType(casesActions.updateLessonNotesAction.success):
      {
        state.interventionGroups = [...state.interventionGroups.map((interventionGroup: InterventionGroup) => {
          return interventionGroup 
                && interventionGroup.id === action.payload.group?.id
                ? {
                    ...interventionGroup,
                    alert_reason: action.payload.group.alert_reason,
                    alert_reason_text: action.payload.group.alert_reason_text,
                  }
                : interventionGroup
          })]
        state.selectedInterventionGroup =
          state.selectedInterventionGroup &&
          state.selectedInterventionGroup.id ===
          action.payload.group?.id
            ? {
                ...state.selectedInterventionGroup,
                alert_reason: action.payload.group.alert_reason,
                alert_reason_text: action.payload.group.alert_reason_text,
              }
            : state.selectedInterventionGroup;
            return {
              ...state,
              isLoading: { ...state.isLoading, updateLessonNotes: false },
              lessonNotes: state.lessonNotes.map((ln) =>
                ln.id === action.payload.id ? action.payload : ln
              ),
            };
      }
    case getType(casesActions.updateLessonNotesAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateLessonNotes: false },
        errors: { ...state.errors, updateLessonNotes: action.payload },
      };

    case getType(casesActions.deleteLessonNotesAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteLessonNotes: true },
        errors: { ...state.errors, deleteLessonNotes: undefined },
      };
    case getType(casesActions.deleteLessonNotesAction.success):
      {
        const { lessonNotesId, lessonNotes } = action.payload;
        state.interventionGroups = [...state.interventionGroups.map((interventionGroup: InterventionGroup) => {
          return interventionGroup 
                && interventionGroup.id === lessonNotes.intervention_group?.id
                ? {
                    ...interventionGroup,
                    alert_reason: lessonNotes.intervention_group.alert_reason,
                    alert_reason_text: lessonNotes.intervention_group.alert_reason_text,
                  }
                : interventionGroup
          })]

          state.selectedInterventionGroup =
          state.selectedInterventionGroup &&
          state.selectedInterventionGroup.id ===
          lessonNotes.intervention_group?.id
            ? {
                ...state.selectedInterventionGroup,
                alert_reason: lessonNotes.intervention_group.alert_reason,
                alert_reason_text: lessonNotes.intervention_group.alert_reason_text,
              }
            : state.selectedInterventionGroup;

          return {
            ...state,
            isLoading: { ...state.isLoading, deleteLessonNotes: false },
            lessonNotes: state.lessonNotes.filter((ln) => ln.id !== lessonNotesId),
          };
      }
    case getType(casesActions.deleteLessonNotesAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteLessonNotes: false },
        errors: { ...state.errors, deleteLessonNotes: action.payload },
      };

    case getType(casesActions.showFbaObservationModal):
      return {
        ...state,
        selectedFba: action.payload.fba,
        isSingleAbc: action.payload.isSingleAbc,
        selectedFbaObservation: action.payload.observation
          ? action.payload.observation
          : state.selectedFbaObservation,
        modalsState: {
          ...state.modalsState,
          fbaObservationModal: true,
        },
      };
    case getType(casesActions.hideFbaObservationModal):
      return {
        ...state,
        selectedFba: undefined,
        isSingleAbc: undefined,
        modalsState: {
          ...state.modalsState,
          fbaObservationModal: false,
        },
      };

    case getType(casesActions.showAnalyzeFbaResultsModal):
      return {
        ...state,
        selectedFba: action.payload,
        modalsState: {
          ...state.modalsState,
          showAnalyzeFbaResultsModal: true,
        },
      };
    case getType(casesActions.hideAnalyzeFbaResultsModal):
      return {
        ...state,
        selectedFba: undefined,
        modalsState: {
          ...state.modalsState,
          showAnalyzeFbaResultsModal: false,
          studentGoalOptions: undefined,
        },
      };

    case getType(casesActions.getAttendancesAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, getAttendances: true },
        errors: { ...state.errors, getAttendances: undefined },
      };
    case getType(casesActions.getAttendancesAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, getAttendances: false },
        attendances: action.payload,
      };
    case getType(casesActions.getAttendancesAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, getAttendances: false },
        errors: { ...state.errors, getAttendances: action.payload },
      };

    case getType(casesActions.createAttendanceAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, createAttendance: true },
        errors: { ...state.errors, createAttendance: undefined },
      };
    case getType(casesActions.createAttendanceAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, createAttendance: false },
        attendances: [action.payload, ...state.attendances],
      };
    case getType(casesActions.createAttendanceAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, createAttendance: false },
        errors: { ...state.errors, createAttendance: action.payload },
      };

    case getType(casesActions.updateAttendanceAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateAttendance: true },
        errors: { ...state.errors, updateAttendance: undefined },
      };
    case getType(casesActions.updateAttendanceAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateAttendance: false },
        attendances: state.attendances.map((ln) =>
          ln.id === action.payload.id ? action.payload : ln
        ),
      };
    case getType(casesActions.updateAttendanceAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, updateAttendance: false },
        errors: { ...state.errors, updateAttendance: action.payload },
      };

    case getType(casesActions.deleteAttendanceAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteAttendance: true },
        errors: { ...state.errors, deleteAttendance: undefined },
      };
    case getType(casesActions.deleteAttendanceAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteAttendance: false },
        attendances: state.attendances.filter((ln) => ln.id !== action.payload),
      };
    case getType(casesActions.deleteAttendanceAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteAttendance: false },
        errors: { ...state.errors, deleteAttendance: action.payload },
      };

    case getType(casesActions.printInterventionGroupReportAction.request):
      return {
        ...state,
        isLoading: { ...state.isLoading, printInterventionGroupReport: true },
        errors: { ...state.errors, printInterventionGroupReport: undefined },
      };
    case getType(casesActions.printInterventionGroupReportAction.success):
      return {
        ...state,
        isLoading: { ...state.isLoading, printInterventionGroupReport: false },
      };
    case getType(casesActions.printInterventionGroupReportAction.failure):
      return {
        ...state,
        isLoading: { ...state.isLoading, printInterventionGroupReport: false },
        errors: {
          ...state.errors,
          printInterventionGroupReport: action.payload,
        },
      };

    case getType(casesActions.selectGroupCardFocusTab):
      return {
        ...state,
        groupCardFocusTab: action.payload,
      };

    case CasesActionTypes.RESET_SELECTED_PDF_INTERVENTION_PLAN_FILTERS:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionPlan: {
            ...state.modalsState.interventionPlan,
            selectedPDFForWorkSpacePDF: pdfTables,
          },
        },
      };

    case getType(casesActions.changeInterventionPlanFilters):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionPlan: {
            ...state.modalsState.interventionPlan,
            ...action.payload,
          },
        },
      };

    case getType(casesActions.showInterventionPlanModal): {
      return {
        ...state,
        selectedInterventionGroup:
          (action.payload as any) ?? state.selectedInterventionGroup,
        modalsState: {
          ...state.modalsState,
          interventionPlan: {
            selectedStudents: [],
            selectedStudentGoals: [],
            selectedStudentGoalMeasure: undefined,
            selectedStudentForWorkSpacePDF: [],
            selectedPDFForWorkSpacePDF: pdfTables,
            showGoalLines: false,
            showStudentTrendsLines: false,
            showOnlyActiveInterventions: true,
            show: true,
          },
        },
      }
    }
    case getType(casesActions.hideInterventionPlanModal):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionPlan: {
            ...state.modalsState.interventionPlan,
            show: false,
          },
        },
        //isClickedFromAlert: false
      };
    
      case getType(casesActions.clickedFromAlert):
        return {
          ...state,
          isClickedFromAlert: action.payload.isClickedFromAlert,
        };

    case getType(casesActions.highlightFba):
      return {
        ...state,
        highlightedFba: action.payload,
      };

    case getType(casesActions.addInterventionImmediately):
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          interventionLibraryModal: true,
          interventionCalledFromFBA: true,
          interventionLibraryModalCallbacks: undefined,
          setGoalModal: false,
          studentGoalOptions: undefined,
        },
        selectedInterventionGroup: action.payload,
      };

    case getType(casesActions.showRecommendations): {
      localStorage.setItem(
        LocalStorageKeys.ShowRecommendedGroups,
        JSON.stringify(!action.payload)
      );
      return {
        ...state,
        showRecommendedGroups: action.payload,
      };
    }

    case CasesActionTypes.LIST_ORDER_SWITCH: {
      return {
        ...state,
        listOrderSwitch: action.payload.listOrderSwitch,
      };
    }

    case getType(casesActions.changeActiveGroupsView): {
      localStorage.setItem(LocalStorageKeys.ActiveGroupsView, action.payload);
      return {
        ...state,
        activeGroupsView: action.payload,
      };
    }

    case CasesActionTypes.RESET_GOAL_CHOOSE_CONCERN_MODAL: {
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          setGoalChooseConcernModel: action.payload.setGoalChooseConcernModel ?? false,
        },
      };
    }

    case CasesActionTypes.SEND_LOG_PROGRESS_DATA_TO_GRAPH: 
      return {
        ...state,
        loggedProgressData: action.payload.loggedProgressData,
      };
    case getType(casesActions.openGroupRecommendationFromData): {
      return {
        ...state,
        openRecommendationGroupFromData: true
      }
    }
    case getType(casesActions.hideGroupRecommendationFromData): {
      return {
        ...state,
        openRecommendationGroupFromData: false
      }
    }  
    default:
      return state;
  }
};

export { reducer as casesReducer };
